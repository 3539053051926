.App {
  text-align: center;
  position: relative;
  height: 100%;
  background-color: #f4f4f4;
  overflow: auto;
}

@media (max-width: 850px) {
  .App {
    height: calc(100% - 71px);
    overflow: auto;
  }
}
.repeaterChild {
  color: red;
}

.Header {
  margin: 0 6%;
  padding-top: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  overflow: auto;
  max-width: 90%;
  background-color: #f4f4f4;
}

.logo {
  color: #56565e;
  font-size: 35px;
  font-weight: 600;
}

.logo > span {
  color: crimson;
  transition: all 0.3s ease;
}

.menu {
  display: flex;
  align-items: center;
}

.menu-items {
  padding-left: 25px;
}

.menu-items > a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.3s ease;
}

.menu-items > a:hover {
  color: crimson;
}

@media (max-width: 850px) {
  .item {
    display: none;
  }
}

.footer-wrapper {
  /* //background-image: url('../images/footer-1.gif'); */
  background-size: contain;
}

.flex {
  display: flex;
}

.inline-block {
  display: inline-block;
}

.flex-center {
  justify-content: center;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: end;
}

.column {
  width: 50%;
  margin: auto 2%;
}

.mr-tp-8 {
  margin-top: 8px;
}

.mr-tp-18 {
  margin-top: 18px;
}

.pd-tp-25 {
  padding-top: 25px;
}

.width-49-pt {
  width: 49%;
}

.width-48-pt {
  width: 48%;
}

.color-crimpson {
  color: crimson;
}

.color-grey {
  color: #56565e;
}

.color-cream {
  color: #f4f4f4;
}

.bg-color-cream {
  background-color: #f4f4f4;
}

.pointer-cursor {
  cursor: pointer;
  transition: color 0.3s ease;
}

.pointer-cursor:hover {
  color: crimson;
}

.text-decoration-none {
  text-decoration: none;
}

.font-5-rem {
  font-size: 5rem;
}

.title {
  position: relative;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 25%;
  height: 3px;
  background: #111;
  transform: translateX(-50%);
}

.title-after {
  position: absolute;
  bottom: -8px;
  left: 50%;
  font-size: 14px;
  color: crimson;
  padding: 0 5px;
  background: #f4f4f4;
  transform: translateX(-50%);
}

.content-wrapper-parent {
  display: flex;
  margin: 0 6%;
  color: #56565e;
  height: 100%;
  padding: 7% 0 13% 0;
}

.content-wrapper {
  display: block;
  align-items: center;
  text-align: left;
  z-index: 1;
  margin: auto 0;
  width: 100%;
}

.content {
  display: flex;
}

.content > .left {
  width: 30%;
  text-align: right;
}

.content > .right {
  width: 50%;
}

.drawer {
  display: none;
}

@media (max-width: 850px) {
  .content {
    flex-direction: column;
  }

  .content-wrapper-parent {
    padding: 15% 0 8% 0;
    height: unset;
  }

  .content > .left,
  .content > .right {
    width: 100%;
    text-align: center;
  }

  .column {
    margin: 0;
  }

  .content > .right {
    margin-top: 20px;
  }

  .title::before {
    width: 70%;
  }

  .title-after {
    width: fit-content;
    font-size: 16px;
  }

  .content-wrapper-parent::-webkit-scrollbar {
    width: 3px;
    height: 6px;
  }

  .content-wrapper-parent::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #c2c9d2;
  }

  .drawer {
    display: block;
  }
}
@font-face {
  font-family: ralewayl;
  src: url(./fonts/Raleway-Light.ttf) format("opentype");
}
@font-face {
  font-family: ralewaym;
  src: url(./fonts/Raleway-Medium.ttf) format("opentype");
}
@font-face {
  font-family: ralewayr;
  src: url(./fonts/Raleway-Regular.ttf) format("opentype");
}
@font-face {
  font-family: ralewayb;
  src: url(./fonts/Raleway-Bold.ttf) format("opentype");
}
@font-face {
  font-family: ralewaysb;
  src: url(./fonts/Raleway-SemiBold.ttf) format("opentype");
}